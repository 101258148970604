<template>
	<canvas ref="canvas"></canvas>
</template>
<script>
import {BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, Tooltip} from 'chart.js';
Chart.register(LinearScale, BarElement, BarController, CategoryScale, Legend, Tooltip);
export default {
	data: () => ({
		myChart: null,
	}),
	props: ['data', 'setKey', 'labelKey', 'axis'],
	mounted () {
		console.log('init chrt', this.getDatasets());
		const ctx = this.$refs.canvas.getContext('2d');
		this.myChart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.getLabel(),
				datasets: this.getDatasets()
			},
			options: {
				indexAxis: this.axis || 'x',
				legend: {
					display: true
				},
				// barThickness: 10,
				// responsive: true,
				// maintainAspectRatio: false,
				// scales: {
				// 	y: { beginAtZero: true }
				// }
			}
		});
	},
	computed: {
		need_update() {
			return JSON.stringify([this.data, this.setKey]);
		}
	},
	watch: {
		need_update() {
			this.update();
		}
	},
	methods: {
		getLabel() {
			if(!this.data || !this.labelKey) return;
			return this.data.map(x => x[this.labelKey]);
		},
		getDatasets() {
			if(!this.data || !this.data.length) return;
			return this.setKey.map(x => ({
				label: x.label,
				data: this.data.map(y => y[x.field]),
				backgroundColor: x.color,
			}));
		},
		update() {
			this.myChart.data.label = this.getLabel();
			this.myChart.data.datasets = this.getDatasets();
			this.myChart.update();
		}
	}
}
</script>
