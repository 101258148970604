<template>
   <!-- <div> -->
      <!-- <div class="d-flex justify-space-between pa-3">
         <div class="title">{{title}}</div>
         <div class="align-self-end" v-if="!noPage">
            <v-icon title="previus page" @click="page--" :disabled="page===1">mdi-arrow-left-circle</v-icon>
            <span class="px-3">Page {{page}} of {{last_page}}</span>
            <v-icon title="next page" @click="page++" :disabled="page===last_page">mdi-arrow-right-circle</v-icon>
         </div>
         <v-btn icon color="red" @click="$emit('close')"><v-icon>close</v-icon></v-btn>
      </div> -->
      <!-- <v-simple-table :height="height">
         <template v-slot:default>
            <thead>
               <tr>
                  <th v-for="(f,i) in field" :key="i" :class="sort===f.value? desc?'desc':'asc':''" @click="sortBy(f.value)">{{f.label}}</th>
               </tr>
            </thead>
            <tbody>
               <tr v-for="(r,i) in dat" :key="i">
                  <td v-for="(f,fi) in field" :key="fi">
                     <v-icon v-for="(b,bi) in f.btn" :key="bi" @click="b.click(r,getData)" :title="b.title" :color="b.color">{{b.ico}}</v-icon>
                     <span v-if="f.idr" v-idr="r[f.value]" />
                     <span v-else-if="f.time" v-dater="r[f.value]" />
                     <span v-else-if="f.cb" v-html="f.cb(r[f.value])"/>
                     <template v-else>{{ f.parent ? r[f.parent] && r[f.parent][f.value_name]:r[f.value] }}</template>
                  </td>
               </tr>
            </tbody>
         </template>
      </v-simple-table> -->
   <!-- </div> -->
   <v-data-table
      :headers="header"
      :items="dat"
      class="elevation-1"
      :search="search"
   />
</template>

<script>
export default {
   props: ['title', 'url', 'field', 'maxWidth', 'noPage', 'dat', 'height'],
   data: () => ({
      loading: false,
      show_picker: false,
      filter: {},
      // dat: [],
      page: 1,
      last_page: 1,
      total: 0,
      sort: null,
      desc: false,
      search: '',
   }),
   computed: {
      header() {
         return this.field.map(x => ({
            text: x.label,
            align: x.align,
            sortable: !x.noSort,
            value: x.value,
         }))
      }
   },
   mounted() {
      console.log('field', this.field)
   },
   watch: {
      url(val) {
         if(val) this.getData();
      }
   },
   methods: {
      getData() {
         clearTimeout(this.timer);
         this.loading = true;
         this.timer = setTimeout(() => {
            this.$api.crud.get(this.url, !this.noPage && {...this.filter, page: this.page, order_by: this.sort, sort_by: this.desc ? 'desc':'asc'}).then(rsl => {
               if(rsl.error) return this.$swal('Ooops!!!', rsl.error, 'error');
               this.dat = rsl.data;
               this.last_page = rsl.last_page;
               this.total = rsl.total;
            }).finally(() => { this.loading = false });
         }, 300);
      },
      sortBy(field) {
         if(this.sort === field) {
            this.desc = !this.desc;
         } else {
            this.sort = field;
         }
         this.getData();
      }
   }
};
</script>
<style scoped>
thead th { padding-right: 40px;}
thead th:hover { color: #acd; cursor: pointer; }
thead th::after {
   font: normal normal normal 24px/1 "Material Design Icons";
   position: absolute;
}
thead th.asc::after { content: "\F0CDB"; } 
thead th.desc::after { content: "\F0CE1"; } 
</style>
