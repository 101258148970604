<template>
   <v-row dense>
      <v-col cols="12" v-if="!!label" class="primary--text">{{label}}</v-col>
      <v-col cols="6">
         <v-menu v-model="show_date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" >
            <template v-slot:activator="{ on }">
               <v-text-field class="mr-2" v-model="date" label="Date" prepend-inner-icon="mdi-calendar" readonly v-on="on" />
            </template>
            <v-date-picker v-model="date" @input="show_date=false" />
         </v-menu>
      </v-col>
      <v-col cols="6">
         <v-menu v-model="show_time" ref="time" :close-on-content-click="false" :return-value.sync="time" transition="scale-transition" offset-y min-width="290px" >
            <template v-slot:activator="{ on }">
               <v-text-field v-model="time" label="Time" prepend-inner-icon="mdi-clock-outline" readonly v-on="on" />
            </template>
            <v-time-picker v-model="time" use-seconds :min="min_hour" :max="max_hour" >
               <v-spacer></v-spacer>
               <v-btn text color="grey" @click="show_time=false">Cancel</v-btn>
               <v-btn text color="primary" @click="$refs.time.save(time)">OK</v-btn>
            </v-time-picker>
         </v-menu>
      </v-col>
   </v-row>
</template>
<script>
import { format } from 'date-fns';
export default {
   name: 'datetime',
   props: ['value','label','min_hour','max_hour'],
   computed: {
      date: {
         set(val) {
            this.$emit('input', val && `${val} ${this.time || '00:00:00'}`);
         },
         get() {
            return this.value && format(new Date(this.value), 'yyyy-MM-dd');
         }
      },
      time: {
         set(val) {
            val && this.$emit('input', `${this.date || format(new Date(), 'yyyy-MM-dd')} ${val}`);
         },
         get() {
            return this.value && format(new Date(this.value), 'HH:mm:ss');
         }
      }
   },
   data: () => ({
      show_date: false,
      show_time: false,
   })
}
</script>