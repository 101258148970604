<template>
   <v-card :style="{backgroundImage: `linear-gradient(to right,${color}, ${color2})`, color:color}" :class="{active:active}">
      <v-card-title class="py-2">
         <v-icon size="24px" :color="active?'white':color" >{{icon}}</v-icon>
         <div class="ml-2 sub-title">{{ title }}</div>
      </v-card-title>
      <v-card-text>
         <div class="text-center data-box" v-for="(d,i) in data" :key="i" >
            <div class="caption">{{d.label}}</div>
            <div class="text-h4 mt-n2" :style="{color: !active ? color:'inherit'}">{{d.value}}</div>
         </div>
      </v-card-text>
      <v-divider v-if="active" class="mx-3" />
      <v-card-actions class="justify-center">
         <v-btn v-if="act" small dark text @click="act">view detail</v-btn>
      </v-card-actions>
   </v-card>
</template>

<script>
export default {
   props: {
      data: { type: Array },
      subTitle: { type: String },
      title: { type: String },
      icon: { type: String },
      color: { type: String },
      color2: { type: String },
      act: { type: Function },
      active: { type: Boolean }
   },
   data() {
      return {
      }
   }
}
</script>

<style scoped>
   .v-card__title, .v-card__text {background-color: inherit;}
   .active > .v-card__title {color: white;background-color: transparent;}
   .active > .v-card__text {color: white;background-color: transparent;}
   .theme--light.v-divider { border-color: #d1d1d1 !important; }
</style>