<template>
   <v-container>
      <v-card>
         <v-card-title>
            Rekap Transaksi
            <v-spacer/>
            <v-btn icon @click="show_up_fm=true" color="primary" title="upload transaksi">
               <v-icon>mdi-file-upload</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-row dense justify="space-between">
               <v-col cols="12" class="primary--text subtitle text-center blue lighten-4">Filter</v-col>
               <v-col cols="12" sm="6" md="5">
                  <date-time label="From" v-model="fm.filter_from_datetime"/>
               </v-col>
               <v-col cols="12" sm="6" md="5">
                  <date-time label="Until" v-model="fm.filter_until_datetime"/>
               </v-col>
               <!-- <v-col cols="12" md="6">
                  <v-row dense>
                  </v-row>
               </v-col> -->
               <v-col cols="12" sm="6" md="5">
                  <v-select label="Status" multiple v-model="fm.transaction_status" :items="statuses"/>
               </v-col>
               <v-col cols="12" sm="6" md="5">
                  <v-select label="Marketplace" multiple v-model="fm.store_id" :items="stores"/>
               </v-col>
               <v-col cols="12" sm="6" md="5">
                  <v-select label="Shipping" multiple v-model="fm.shipping_id" :items="shippings"/>
               </v-col>
               <v-col cols="12" sm="6" md="5">
                  <v-select label="Payment" multiple v-model="fm.payment_id" :items="payments"/>
               </v-col>
            </v-row>
            <v-row class="my-3" justify="center" style="background-color:rgba(128,128,128,0.2)">
               <v-col cols="12" sm="4" lg="3">
                  <widget title="TRANSAKSI" color="#64b3c1" color2="#7c71d5" icon="mdi-cash" :data="transaction_wid" :act="()=>apply('transaction')" :active="mod==='transaction'" />
               </v-col>
               <v-col cols="12" sm="4" lg="3">
                  <widget title="PRODUK" color="#9e69d2" color2="#e669ab" icon="mdi-animation-outline" :data="product_wid" :act="()=>apply('product')" :active="mod==='product'" />
               </v-col>
               <v-col cols="12" sm="4" lg="3">
                  <widget title="PELANGGAN" color="#e669ab" color2="#ff7c75" icon="mdi-account-circle-outline" :data="customer_wid" :act="()=>apply('customer')" :active="mod==='customer'" />
               </v-col>
            </v-row>
            <v-row v-if="mod==='transaction' && transaction_data.daily_sales">
               <v-col cols="12" class="primary--text">Harian</v-col>
               <v-col cols="12" lg="6">
                  <label class="text-h5">Transaksi VS Produk</label>
                  <chart-bar
                     label-key="interval_datetime"
                     :set-key="[
                        {label: 'Transaksi', field: 'transaction_quantity', color: 'blue'},
                        {label: 'Product', field: 'product_quantity', color: 'red'},
                     ]"
                     :data="transaction_data.daily_sales"
                  />
               </v-col>
               <v-col cols="12" lg="6">
                  <label class="text-h5">Nilai Transaksi</label>
                  <chart-bar
                     label-key="interval_datetime"
                     :set-key="[
                        {label: 'Nilai', field: 'transaction_amount', color: 'green'},
                     ]"
                     :data="transaction_data.daily_sales"
                  />
               </v-col>
            </v-row>
            <v-row v-if="mod==='transaction' && transaction_data.hourly_sales">
               <v-col cols="12" class="primary--text">Per Jam</v-col>
               <v-col cols="12" lg="6">
                  <label class="text-h5">Transaksi VS Produk</label>
                  <chart-bar
                     axis="y"
                     label-key="interval_datetime"
                     :set-key="[
                        {label: 'Transaksi', field: 'transaction_quantity', color: 'blue'},
                        {label: 'Product', field: 'product_quantity', color: 'red'},
                     ]"
                     :data="transaction_data.hourly_sales"
                  />
               </v-col>
               <v-col cols="12" lg="6">
                  <label class="text-h5">Nilai Transaksi</label>
                  <chart-bar
                     axis="y"
                     label-key="interval_datetime"
                     :set-key="[
                        {label: 'Nilai', field: 'transaction_amount', color: 'green'},
                     ]"
                     :data="transaction_data.hourly_sales"
                  />
               </v-col>
            </v-row>
            <v-row v-if="mod==='product' && product_data.top_product">
               <v-col cols="12" lg="6">
                  <label class="text-h5">Produk Terjual</label>
                  <chart-bar
                     label-key="product_name"
                     :set-key="[
                        {label: 'Produk', field: 'product_sold', color: 'teal'},
                     ]"
                     :data="product_data.top_product"
                  />
               </v-col>
               <v-col cols="12" lg="6">
                  <label class="text-h5">Detail perjualan</label>
                  <tabel :dat="product_data.detail_sales" :field="prod_field"/>
               </v-col>
            </v-row>
            <v-row v-if="mod==='customer' && customer_data.summary_per_customer">
               <v-col cols="12" class="text-h5">Rekap Pelanggan</v-col>
               <v-col cols="12" lg="6">
                  <!-- <div class="d-flex">
                     <v-spacer/>
                     <v-chip-group v-model="cust_idx" active-class="primary--text" column >
                        <v-chip small>Transaksi</v-chip>
                        <v-chip small>Produk</v-chip>
                        <v-chip small>Nilai</v-chip>
                     </v-chip-group>
                  </div> -->
                  <chart-bar
                     axis="y"
                     label-key="customer_name"
                     :set-key="[ cust_map[0] ]"
                     :data="customer_data.summary_per_customer"
                  />
                  <chart-bar
                     axis="y"
                     label-key="customer_name"
                     :set-key="[ cust_map[1] ]"
                     :data="customer_data.summary_per_customer"
                  />
                  <chart-bar
                     axis="y"
                     label-key="customer_name"
                     :set-key="[ cust_map[2] ]"
                     :data="customer_data.summary_per_customer"
                  />
               </v-col>
               <v-col cols="12" lg="6">
                  <!-- <label class="text-h5">Detail penjualan per pelanggan</label> -->
                  <tabel :dat="customer_data.summary_per_customer" :field="cust_field"/>
               </v-col>
               <v-col cols="12">
                  <div class="text-h5">Detail Penjualan</div>
                  <tabel :dat="customer_data.detail_sales" :field="cust_sale_field"/>
               </v-col>
            </v-row>
         </v-card-text>
      </v-card>
      <!-- uload form modal -->
      <v-dialog v-model="show_up_fm" persistent >
         <v-card>
            <v-card-title>
               Upload Transaksi
               <v-spacer/>
               <v-btn color="red" icon @click="show_up_fm=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-row dense class="ma-0">
               <v-col cols="5">
                  <v-file-input dense label="File transaksi" v-model="fm.file_upload" name="file" />
               </v-col>
               <v-col cols="5">
                  <v-select label="Marketplace" dense v-model="fm.ms_store_identifier" :rules="[v => !!v || 'Lapak belum ditentukan']" :items="stores.map(x => x.text)"/>
               </v-col>
               <v-col cols="2">
                  <v-btn color="primary" outlined @click="upload" :loading="uploading">
                     <v-icon>mdi-arrow-up-bold</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
            <v-divider class="mx-4"/>
            <v-card-text v-if="upload_data.table_content && upload_data.table_content.length">
               <tabel height="480" :dat="upload_data.table_content" :field="Object.keys(upload_data.table_content[0]).map(x => ({value: x, label: x}))"/>
               <v-divider class="mx-4"/>
               <div class="text-center mt-3">
                  <v-btn color="primary" outlined @click="process" width="160" :loading="uploading">
                     <v-icon>mdi-location-enter</v-icon> proses
                  </v-btn>
               </div>
            </v-card-text>
         </v-card>
      </v-dialog>
   </v-container>
</template>

<script>
import api from '@/api';
import DateTime from '../components/DateTime.vue';
import Widget from '../components/Widget.vue';
import ChartBar from '../components/ChartBar.vue';
import Tabel from '../components/Tabel.vue';

export default {
   components: { DateTime, Widget, ChartBar, Tabel, },
   data: () => ({
      mod: null,
      cust_idx: 0,
      cust_map: [
         {label: 'Jumlah Transaksi', field: 'transaction_quantity', color: 'green'},
         {label: 'Jumlah Produk', field: 'product_quantity', color: 'orange'},
         {label: 'Nominal Transaksi', field: 'transaction_amount', color: 'cyan'},
      ],
      cust_field: [
         {value: 'customer_name', label: 'Pelanggan'},
         // {value: 'customer_msisdn', label: 'Telp'},
         {value: 'product_quantity', label: 'Jumlah Produk'},
         {value: 'transaction_quantity', label: 'Jumlah Transaksi'},
         {value: 'transaction_amount', label: 'Nominal Transaksi', idr: true},
         // {value: 'customer_city', label: 'Lokasi'},
         {value: 'average_product_quantity_per_transaction', label: 'Rata-rata produk'},
         {value: 'average_transaction_amount_per_transaction', label: 'Rata-rata Transaksi', idr: true},
      ],
      cust_sale_field: [
         {value: 'customer_name', label: 'Pelanggan'},
         {value: 'customer_msisdn', label: 'Telp'},
         {value: 'customer_city', label: 'Lokasi'},
         {value: 'interval_datetime', label: 'Tanggal'},
         {value: 'product_quantity', label: 'Jumlah Produk'},
         {value: 'transaction_amount', label: 'Nominal Transaksi', idr: true},
      ],
      fm: {},
      transaction_data: {},
      product_data: {},
      prod_field: [
         {value: 'transaction_number', label: 'No transaksi'},
         {value: 'transaction_created_datetime', label: 'Waktu', time: true},
         {value: 'product_name', label: 'Produk'},
         {value: 'product_sold', label: 'Terjual'},
      ],
      customer_data: {},
      upload_data: {},
      statuses: [],
      payments: [],
      shippings: [],
      load_data: false,
      stores: [],
      uploading: false,
      search_cust: null,
      show_up_fm: false,
   }),
   computed: {
      transaction_wid() {
         return this.transaction_data.main_sales && [
            {label: 'Transaksi', value: this.transaction_data.main_sales.transaction_quantity},
            {label: 'Produk', value: this.transaction_data.main_sales.product_quantity},
            {label: 'Nominal (IDR)', value: this.transaction_data.main_sales.transaction_amount && this.transaction_data.main_sales.transaction_amount.toLocaleString('id-ID')},
            {label: 'Total Hari', value: this.transaction_data.main_sales.total_days},
            {label: 'Transaksi Rata-rata', value: this.transaction_data.main_sales.average_transaction_quantity_each_day},
            {label: 'Produk Terjual Rata-rata', value: this.transaction_data.main_sales.average_product_quantity_each_day},
            {label: 'Nominal Rata-rata (IDR)', value: this.transaction_data.main_sales.average_transaction_amount_each_day && this.transaction_data.main_sales.average_transaction_amount_each_day.toLocaleString('id-ID')},
         ] || [];
      },
      product_wid() {
         return this.product_data.main_sales && [
            {label: 'Total Terjual', value: this.product_data.main_sales.total_sold},
            {label: 'Total Hari', value: this.product_data.main_sales.total_days},
            {label: 'Rata-rata Terjual', value: this.product_data.main_sales.average_product_sold_each_day},
         ] || [];
      },
      customer_wid() {
         return this.customer_data.main_sales && [
            {label: 'Pelanggan', value: this.customer_data.main_sales.total_customer},
            {label: 'Produk', value: this.customer_data.main_sales.product_quantity},
            {label: 'Nominal (IDR)', value: this.customer_data.main_sales.transaction_amount && this.customer_data.main_sales.transaction_amount.toLocaleString('id-ID')},
         ] || [];
      },
   },
   watch: {
      det_id(val) {
         if(!val) return;
         this.load_detail = true;
         api.get(`transaction/${val}`).then(rsl => {
            if(rsl.data) this.view = rsl.data;
            else {
               this.$alert('Ooops!', rsl.msg, 'notification');
               this.view = [];
            }
         }).finally(() => { this.load_detail = false });
      },
      search_cust(val) {
         clearTimeout(this.event_timer);
         this.event_timer = setTimeout(() => {
            this.load_cust = true;
            api.get('cust', {name: val}).then(rsl => {
               this.store = rsl.data.map(x => ({value: x.id, text: x.name}));
            }).finally(() => { this.load_cust = false });
         }, 1000);
      },
      show_up_fm(val) {
         if(val) return;
         this.fm = {};
         this.upload_data = {};
      }
   },
   mounted() {
      api.get('list/approval_status').then(rsl => {
         this.statuses = rsl.data.map(x => ({value: x.status_id, text: x.status_name}));
      });
      api.get('list/ms_store').then(rsl => {
         this.stores = rsl.data.map(x => ({value: x.ms_store_id, text: x.ms_store_identifier}));
      });
      api.get('payment').then(rsl => {
         this.payments = rsl.data.map(x => ({value: x.payment_id, text: x.payment_name}));
      });
      api.get('shipping').then(rsl => {
         this.shippings = rsl.data.map(x => ({value: x.shipping_id, text: x.shipping_name}));
      });
   },
   methods: {
      filterEvent(val) {
         this.field[2].filter_value = val;
         this.$refs.crud.getData();
      },
      print() {
         api.get(`transaction/${this.det_id}/print`).then(rsl => {
            if(rsl.status === 1) {
               this.$alert({title:'Berhasil', text:rsl.msg, type:'notification', timer:2000});
            } else this.$alert('Ooops!', rsl.msg, 'error');
         });
         // this.$router.push();
         // window.open(`receipt/${this.det_id}`);
      },
      apply(mod) {
         const data = {transaction_status: [],store_id: [],shipping_id: [],payment_id: [], product_id: []};
         this.load_data = true;
         api.create('report/'+ mod, {...data, ...this.fm}).then(rsl => {
            if(rsl.error) return this.$alert('Ooops!', rsl.error, 'error');
            this.mod = mod;
            this[`${mod}_data`] = rsl.data;
         }).finally(() => { this.load_data = false });
      },
      upload() {
         const fd = new FormData();
         for(let i in this.fm) fd.append(i, this.fm[i]);
         this.uploading = true;
         api.create('transaction/upload', fd).then(rsl => {
            if(rsl.error) return this.$alert('Ooops!', rsl.error, 'error');
            this.upload_data = rsl.data;
         }).finally(() => { this.uploading = false });
      },
      process() {
         const data = {file_name: this.upload_data.file_name, ms_store_identifier: this.fm.ms_store_identifier};
         this.uploading = true;
         api.create('transaction/process', data).then(rsl => {
            if(rsl.error) return this.$alert('Ooops!', rsl.error, 'error');
            if(rsl.success) {
               this.$alert({title:'Berhasil', text:rsl.success, type:'notification', timer:2000});
               this.show_up_fm = false;
            }
         }).finally(() => { this.uploading = false });
      }
   }
}
</script>
<style scoped>
.list-box {max-height: 200px;overflow: auto;}
.underline {display: flex;justify-content: space-between;align-items: center;min-height: 36px;border-bottom: 1px solid #ddd;}
</style>